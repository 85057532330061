<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
<!--        <el-upload-->
<!--            class="upload-demo"-->
<!--            multiple:false-->
<!--            action="http://localhost:9403/sys-api/file-api/uploadProtocol"-->
<!--            :headers="config.headers"-->
<!--            accept=".txt"-->
<!--            show-file-list:false-->
<!--            auto-upload:true-->
<!--            :before-upload="beforeUpload"-->
<!--        >-->
<!--          <el-button size="small" type="primary">点击上传</el-button>-->
<!--        </el-upload>-->
        <el-form :model="form"  ref="form">
          <el-form-item  prop="fileName">
            <el-button type="primary" style="margin-right: 10px" size="small"  @click="() => {$refs.upload_file.click()}">上传协议</el-button>
            <input class="upload_file" type="file" ref="upload_file" accept=".txt"  @change="uploadFile"/>

            <!--<el-button type="primary" style="margin-right: 10px" size="small"  @click="() => {$refs.upload_file_voice_open.click()}">上传开锁语音</el-button>
            <input class="upload_file" type="file" ref="upload_file_voice_open" accept=".mp3"  @change="uploadVoice(0)"/>

            <el-button type="primary" style="margin-right: 10px" size="small"  @click="() => {$refs.upload_file_voice_close.click()}">上传关锁语音</el-button>
            <input class="upload_file" type="file" ref="upload_file_voice_close" accept=".mp3"  @change="uploadVoice(1)"/>-->

            <el-button type="primary" style="margin-right: 10px" size="small"  @click="() => {$refs.upload_file_wheel.click()}">上传轮椅apk</el-button>
            <input class="upload_file" type="file" ref="upload_file_wheel" accept=".apk"  @change="uploadWheel"/>
          </el-form-item>
        </el-form>

      </div>
      <div class="ft">
        <el-button size="small" type="primary" @click="handleUploadApk(true)">上传apk</el-button>
        <el-button size="small" type="primary" :loading="upgradeLoading" @click="upgradeApk">apk升级</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="版本号"
            prop="versionsNumber"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="版本信息"
            prop="versionsMessage"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
          <el-table-column
                  label="文件大小"
                  prop="versionCode"
                  align="center"
                  header-align="center"
                  :formatter="commonFormatter"
          ><template slot-scope="{row}">
              {{ (row.versionCode/(1024*1024)).toFixed(2) + "MB" }}
          </template>
          </el-table-column>
        <el-table-column
            label="上传时间"
            prop="createTime"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="文件类型"
            prop="apkType"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="scope">
            <span>{{
                scope.row.apkType === 0 ? "陪护床apk" : scope.row.apkType === 1 ? '轮椅apk' : scope.row.apkType === 2 ? "轮椅开锁语音" : scope.row.apkType === 3 ? "轮椅关锁" : scope.row.apkType === 4 ? "用户协议" : "-"
              }} </span>
          </template>
        </el-table-column>
          <el-table-column
                  align="center"
                  header-align="center"
                  label="操作">
              <template slot-scope="scope">
                  <el-button type="primary" size="mini" @click="goEdit(true,scope.row)" icon="el-icon-edit"></el-button>
              </template>
          </el-table-column>
      </el-table>
    </div>
<!--    <el-pagination-->
<!--        class="top_10 right right_10"-->
<!--        :current-page="pageNum"-->
<!--        :page-sizes="pageSizes"-->
<!--        :page-size="pageSize"-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--        layout="total, sizes, prev, pager, next, jumper"-->
<!--        :total="total">-->
<!--    </el-pagination>-->
    <UploadApk :dialogVisible="uploadApkDialogVisible" :currentData="currentData" @close="handleUploadApk"></UploadApk>
  </div>
</template>

<script>
import axios from 'axios'
import tableUse from "@/mixins/tableUse";
import UploadApk from "@/views/apk/UploadApk";
export default {
  name: "ApkList",
  mixins:[tableUse],
  components:{
    UploadApk
  },
  data(){
    return {
      config:{
        headers:{}
      },
      form:{
        file:'',
        fileName:'',
        fileMessage:''
      },
      currentData:{},
      tableData:[],
      uploadApkDialogVisible:false,
      upgradeLoading:false
    }
  },
  methods:{
    uploadFile(){
      let file = this.$refs.upload_file.files[0];
      let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (extName.indexOf('txt') === -1) {
        this.$message.error("只能上传txt文件");
        return
      }
      this.form.file = file;
      this.form.fileName = file.name;

            this.loading = true;
            let formData = new FormData();
            formData.append('file',this.form.file)
            this.$axios.form('/sys-api/file-api/uploadProtocol',formData,(res) => {
              this.loading = false;
              if(res.code === '100'){
                this.$message.success("上传成功");
                this.closeModal(true);
              }
            })
      this.getTableData()
    },

    uploadWheel(){
        let file = this.$refs.upload_file_wheel.files[0];
        let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        if (extName.indexOf('apk') === -1) {
          this.$message.error("只能上传apk文件");
          return
        }
        this.form.file = file;


        this.loading = true;
        let formData = new FormData();
        formData.append('file',this.form.file)
        this.$axios.form('/sys-api/apk-api/uploadingApkforWheeel',formData,(res) => {
          this.loading = false;
          if(res.code === '100'){
            this.$message.success("apk上传成功");
            this.closeModal(true);
          }
        })
      this.getTableData()
    },

    uploadVoice(flag){
      if (flag === 0)
      {
        //开锁
        let file = this.$refs.upload_file_voice_open.files[0];
        let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        if (extName.indexOf('mp3') === -1) {
          this.$message.error("只能上传mp3文件");
          return
        }
        this.form.file = file;
        this.form.fileName = file.name;



        console.log(this.form.file)
        this.loading = true;
        let formData = new FormData();
        formData.append('file',this.form.file)
        console.log(formData)

        axios.post(
          `/sys-api/file-api/uploadVoice/2`,formData,{'Content-type' : 'multipart/form-data'}).then(
              (res) => {
                console.log(res)
                this.loading = false;
                if (res.data.code === '100') {
                  this.$message.success("语音上传成功");
                  this.closeModal(true);
                }
              })

        // this.$axios.form('/sys-api/file-api/uploadVoice/2',formData,(res) => {
        //   this.loading = false;
        //   if(res.code === '100'){
        //     this.$message.success("开锁语音上传成功");
        //     this.closeModal(true);
        //   }
        // })
      }
      else {
        //关锁
        let file = this.$refs.upload_file_voice_close.files[0];
        let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        if (extName.indexOf('mp3') === -1) {
          this.$message.error("关锁只能上传mp3文件");
          return
        }
        this.form.file = file;


        this.loading = true;
        let formData = new FormData();
        formData.append('file',this.form.file)
        this.$axios.form('/sys-api/file-api/uploadVoice/3',formData,(res) => {
          this.loading = false;
          if(res.code === '100'){
            this.$message.success("关锁语音上传成功");
            this.closeModal(true);

          }
        })
      }
      this.getTableData()
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const extension3 = testmsg === 'doc'
      const extension4 = testmsg === 'pdf'
      const extension5 = testmsg === 'txt'
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        this.$message({
          message: '上传文件只能是pdf、doc、xls、xlsx、txt格式!',
          type: 'warning'
        })
      }
      return extension || extension2 || extension3 || extension4 ||extension5
    },
      goEdit(visible,data){
          this.currentData = data;
          this.uploadApkDialogVisible = visible;
      },
    handleUploadApk(visible,isRe){
        this.currentData = {};
      this.uploadApkDialogVisible = visible;
      if(isRe) this.getTableData();
    },
    upgradeApk(){
        this.$confirm('确定升级吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.upgradeLoading = true;
            this.$axios.post("/sys-api/apk-api/apkAll",{},(res) => {
                this.upgradeLoading = false;
                if(res.code === '100'){
                    this.$message.success("已通知设备升级apk")
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消'
            });
        });
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      // this.$axios.post("/sys-api/apk-api/apkPage",formData,(res) => {
      //   this.tableLoading = false;
      //   if(res.code === '100'){
      //     this.tableData = res.data.list;
      //     this.total = res.data.total;
      //   }
      // })
      this.$axios.post("/sys-api/apk-api/selectApk",null,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.tableData = res.data;
          // this.total = res.data.total;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
    // let userInfo = window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))
    // // this.config.headers = userInfo
    // this.config.headers.loginType = 1;
    // if (userInfo && userInfo.token) this.config.headers.token = userInfo.token;
    // if (userInfo && userInfo.mobile) this.config.headers.mobile = userInfo.mobile;
    // console.log(this.config.headers)
  }
}
</script>

<style scoped lang="scss">
.upload_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
</style>
