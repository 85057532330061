<template>
  <el-dialog
      :title="this.isShow ? '上传apk' : '编辑'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      width="420px">
    <el-form label-position="right" class="common-form" label-width="100px"
              :model="form" :rules="rules" ref="form">
      <el-form-item label="apk文件：" v-if="this.isShow" prop="fileName">
        <el-button type="primary" size="small"  @click="() => {$refs.upload_file.click()}">上传</el-button>
        <input class="upload_file" type="file" ref="upload_file" accept=".apk"  @change="uploadFile"/>
        {{form.fileName}}
      </el-form-item>
      <el-form-item label="版本号：" prop="versionsNumber">
        <el-input v-model="form.versionsNumber" placeholder="请输入版本号"></el-input>
      </el-form-item>
      <el-form-item label="版本信息：" prop="versionsMessage">
        <el-input type="textarea" :rows="3" class="small-input" v-model="form.versionsMessage"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="closeModal">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "UploadApk",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentData: {
      type: Object,
      default() {
        return null;
      }
    },
  },
  watch:{
    dialogVisible(val){
      if(!val){
        this.form = {
          fileName: '',
          file:'',
          versionsMessage:'',
          versionsNumber:"",
          versionsCode:""
        }
        this.$refs.form.resetFields();
      }else {
        if (typeof (this.currentData.id) != 'undefined') {
          this.isShow = false;
          this.form = this.currentData;
        }else {
          this.isShow = true;
        }
      }
    }
  },
  data(){
    return {
      isShow:true,
      form:{
        file:'',
        fileName:'',
        versionsMessage:''
      },
      rules:{
        fileName:[
          {required: true, message: '请上传apk文件', trigger: 'change'},
        ],
        versionsNumber:[
          {required: true, message: '请填写版本号', trigger: 'change'},
        ]
      },
      loading:false
    }
  },
  methods: {
    handleClose() {
      this.closeModal()
    },
    closeModal(isRe){
      this.$emit("close", false,isRe);
    },
    uploadFile(){
      let file = this.$refs.upload_file.files[0];
      let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (extName.indexOf('apk') === -1) {
        this.$message.error("只能上传apk文件");
        return
      }
      this.form.file = file;
      this.form.fileName = file.name;
      this.form.versionsCode = file.size;
    },
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = new FormData();
          if (typeof (this.currentData.id) != 'undefined') {
            formData.append('id',this.currentData.id);
            formData.append('versionsMessage',this.form.versionsMessage);
            formData.append('versionsNumber',this.form.versionsNumber);
            this.$axios.form('/sys-api/apk-api/updateApk',formData,(res) => {
              this.loading = false;
              if(res.code === '100'){
                this.$message.success("保存成功");
                this.closeModal(true);
              }
            })
          }else {
            formData.append('file',this.form.file);
            formData.append('versionsMessage',this.form.versionsMessage);
            formData.append('versionsNumber',this.form.versionsNumber);
            formData.append('versionCode',this.form.versionsCode);
            this.$axios.form('/sys-api/apk-api/uploadingApk',formData,(res) => {
              this.loading = false;
              if(res.code === '100'){
                this.$message.success("apk上传成功");
                this.closeModal(true);
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
</style>
